$( document ).ready(function() {

function blogSlider() {
    $('.blog-slider__container').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    var prevButton = $('.blog-slider__buttons__button--left');
    var nextButton = $('.blog-slider__buttons__button--right');

    prevButton.click(function() {
        $('.blog-slider__container').slick('slickPrev');
    });

    nextButton.click(function() {
        $('.blog-slider__container').slick('slickNext');
    });
}

function recipeSlider() {
    $('.recipe-slider__container').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    var prevButton = $('.recipe-slider__buttons__button--left');
    var nextButton = $('.recipe-slider__buttons__button--right');

    prevButton.click(function() {
        $('.recipe-slider__container').slick('slickPrev');
    });

    nextButton.click(function() {
        $('.recipe-slider__container').slick('slickNext');
    });
}

function careersDropdown() {
    if(document.querySelector('.faqs')) {

        //Career Dropdown
        var dropdowns = '.faqs__single';
        $(dropdowns).each(function(){
            var currentDropdown = this;
            var dropdownContent = $(currentDropdown).find('.faqs__single__content');
            var dropdownHeight = dropdownContent.find('.faqs__single__content__inner').css("height");
            console.log(dropdownHeight);

            $(currentDropdown).find('.faqs__single__title').click(function(){
                $(currentDropdown).toggleClass("faqs__single--active");

                if($(dropdownContent).css("max-height") !== '0px') {
                    $(dropdownContent).css("max-height", '0px');
                }else {
                    $(dropdownContent).css("max-height", dropdownHeight);
                }
            });
        });

    }
}

blogSlider();
recipeSlider();

window.onload = function() {
	careersDropdown();
};


});